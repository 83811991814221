import { Component } from '@angular/core';
import { AppService } from '../app.service';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import { faHeart as faHeartSolid, } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  currentRate = 3.14;
  hoveredRate = 0;
  readonly = false;

  faHeartRegular = faHeartRegular;
  faHeartSolid = faHeartSolid;


  course1: any = {
    title : 'Java COMPLETO 2020 Programação Orientada a Objetos + Projetos',
    owner: 'Nelio Alves ',
    price : 39.90,
    currentRate: 4.5,
    rateCount: 20,
    isBestSeller: true,
    isHighScored: true,
    isNew: true,
    image: '../../assets/img/bg/java_mn.jpg',
    isWished: false
  };

  course2: any = {
    title : 'Python 3 COMPLETO - Do iniciante ao avançado!',
    owner: 'Courses Data Inc., Gustavo Sulivam Tadewald.',
    price : 59.90,
    currentRate: 3.5,
    rateCount: 20,
    isBestSeller: true,
    isHighScored: true,
    isNew: false,
    image: '../../assets/img/bg/python_mn.jpg',
    isWished: true
  };

  
  course3: any = {
    title : 'Web Design: Construa Sites com HTML, CSS e JavaScript',
    owner: 'Alfahelix Treinamentos, Diego Mariano, Ph.D.',
    price : 19.90,
    currentRate: 5,
    rateCount: 20,
    isBestSeller: true,
    isHighScored: true,
    isNew: false,
    image: '../../assets/img/bg/webdesign_mn.jpg',
    isWished: false
  };

  courses: any[] = [this.course1, this.course2, this.course3];

  constructor(private appService: AppService, config: NgbRatingConfig) {
    this.appService.pageTitle = 'Home';
    config.max = 5;
    this.readonly = config.readonly = true;
  }

}
