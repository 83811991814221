<div class="jumbotron ui-hero ui-bg-cover ui-bg-overlay-container text-white bg-primary" style="background-image: url('./../../assets/img/bg/21.jpg');">
  <div class="ui-bg-overlay bg-dark opacity-25"></div>
  <div class="container">
    <div class="row">
      <div class="w-100">

        <div class="col-md-10 col-lg-8 col-xl-6 text-center mx-auto">

          <h3 class="display-4 font-weight-bolder mb-5">
            INSPIRATION.<br>
            EDUCATION.<br>
            CONSERVATION.
          </h3>
          <!-- <h1 class="display-4 mb-5">
            Find the perfect asset for your next creative project
          </h1> -->
        </div>

        <div class="col-lg-9 col-xl-8 mx-auto text-center">
            <a href="#" class="btn btn-outline-white btn-lg font-weight-bold text-center">Enquire Now</a>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="courses-content">
  <h2 class="ui-block-heading" style="margin-bottom: 1.5rem;margin-top: 2.5rem; text-align: center;">Top rated courses</h2>

    <div class="card-deck">
      <div class="app-item">
        <app-course-card  [course]="course1">
        </app-course-card>
      </div>
      <div class="app-item">
        <app-course-card [course]="course2">
        </app-course-card>
      </div>

      <div class="app-item">
        <app-course-card [course]="course3">
        </app-course-card>
      </div> 

      <div class="app-item">
        <app-course-card  [course]="course1">
        </app-course-card>
      </div>
      <div class="app-item">
        <app-course-card [course]="course2">
        </app-course-card>
      </div>

      <div class="app-item">
        <app-course-card [course]="course3">
        </app-course-card>
      </div>

      <div class="app-item">
        <app-course-card  [course]="course1">
        </app-course-card>
      </div>
      <div class="app-item">
        <app-course-card [course]="course2">
        </app-course-card>
      </div>

      <div class="app-item">
        <app-course-card [course]="course3">
        </app-course-card>
      </div>

      <div class="app-item">
        <app-course-card  [course]="course1">
        </app-course-card>
      </div>

    </div>
</div>


