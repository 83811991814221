import { AboutComponent } from './about/about.component';
import { RegisterComponent } from './auth/register/register.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';
import { LayoutWithoutSidenavComponent } from "./layout/layout-without-sidenav/layout-without-sidenav.component";
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { LoginComponent } from './auth/login/login.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  { path: '', component: LayoutWithoutSidenavComponent, pathMatch: 'full', children: [
    { path: '', component: HomeComponent },
  ]},

  { path: 'login', component: LayoutBlankComponent, children: [
    { path: '', component: LoginComponent },
  ]},

  { path: 'register', component: LayoutBlankComponent, children: [
    { path: '', component: RegisterComponent },
  ]},

  { path: 'about', component: LayoutWithoutSidenavComponent, children: [
    { path: '', component: AboutComponent },
  ]},

  { path: 'courses', component: LayoutWithoutSidenavComponent, children: [
    { path: '', component: Page2Component },
  ]},

  // 404 Not Found page
  { path: '**', component: NotFoundComponent }

];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
