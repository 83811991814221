<div class="card">
    <div class="my-card-header" style="position: relative;">
        <img class="card-img-top" src="{{ course.image }}" alt="Card image cap">
        <button type="button" class="btn borderless heart-btn">
            <fa-icon [hidden]="!course.isWished" (click)="toggleWished($event)" [icon]="faHeartSolid" size="2x" [styles]="{'stroke': 'red', 'color': '#d9534f'}"></fa-icon>
            <fa-icon [hidden]="course.isWished" (click)="toggleWished($event)" [icon]="faHeartRegular" size="2x" [styles]="{'stroke': 'yelow', 'color': '#fff'}"></fa-icon>
          </button>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ course.title }}</h5>
      <p class="card-text">{{ course.owner }} </p>
      <!-- BADGES -->
      <div class="demo-inline-spacing" style="padding: 0.25rem 0rem;">
        <a [hidden]="!course.isNew || course.isHighScored" href="javascript:void(0)" class="badge badge-success">Novo</a>
        <a [hidden]="!course.isBestSeller" href="javascript:void(0)" class="badge badge-warning">Mais vendidos</a>
        <a [hidden]="!course.isHighScored || course.isNew" href="javascript:void(0)" class="badge badge-primary">Classificação mais alta</a>
        <a [hidden]="!course.isHighScored || !course.isNew"href="javascript:void(0)" class="badge badge-danger">Novo e em alta</a>

      </div>

      <!-- RATING -->
      <div style="padding: 0.25rem 0rem;">
        <span style="color: brown;">{{ course.currentRate }}</span>
        <ngb-rating style="color: darkorange;" [(rate)]="course.currentRate" (hover)="hoveredRate=$event" (leave)="hoveredRate=0" [readonly]="readonly"></ngb-rating>
        <small class="text-muted">({{ course.rateCount }})</small>
      </div>
      
      <!-- PRICE -->
      <span>
        <span>€ {{ course.price }}</span>
      </span>
      
    </div>
    
    <!-- <div class="card-footer">
                  <button type="button" class="btn icon-btn btn-outline-danger borderless">
        <fa-icon [icon]="faHeartSolid" size="2x" ></fa-icon>
        
      </button>
      <button type="button" class="btn rounded-pill btn-danger">
        <span class="fas fa-heart">Wishlist</span>
        <fa-icon [icon]="faHeartRegular"></fa-icon>
      </button>
    </div> -->
  </div>

