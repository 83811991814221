<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

  <!-- Brand -->
  <a routerLink="/" class="navbar-brand text-large font-weight-bolder line-height-1 py-3">eDc</a>

  <!-- Sidenav toggle -->
  <div class="navbar-nav align-items-lg-center mr-auto mr-lg-4" *ngIf="sidenavToggle">
    <a class="nav-item nav-link px-0 ml-2" href="javascript:void(0)" (click)="toggleSidenav()">
      <i class="ion ion-md-menu text-large align-middle"></i>
    </a>
  </div>

  <!-- Navbar toggle -->
  <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
    <span class="navbar-toggler-icon"></span>
  </button>

  <form class="header-9 navbar-collapse collapse col-lg-6 order-lg-2 w-auto py-3 py-lg-0 px-0">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text ion ion-ios-search"></div>
      </div>
      <input type="text" class="form-control" placeholder="Search...">
      <select class="custom-select pl-2">
        <option>All</option>
        <option>Shoes</option>
        <option>Shirts</option>
        <option>Jeans</option>
      </select>
    </div>
  </form>
  
  <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
    <div class="navbar-nav ml-auto">
      <div class="nav-item"><a class="nav-link" routerLink="/courses">Cursos</a></div>
      <div class="nav-item"><a class="nav-link" routerLink="/about">Sobre</a></div>
      <div class="nav-item"><a class="nav-link" routerLink="/register">Cadastre-se</a></div>
      <div class="nav-item"><a class="nav-link" routerLink="/login">Login</a></div>
    </div>
  </div>

</nav>
