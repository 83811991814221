<nav class="footer bg-white py-2" [ngClass]="currentBg()">
  <div class="container d-flex flex-wrap align-items-center text-center pb-3">
    <div class="text-nowrap pt-3 mr-5">
      <a href="#" class="footer-text text-large font-weight-bolder">eDc</a> 
      <!-- <span class="align-top">©</span> -->
    </div>
    <div>
      <a href="#" class="footer-link pt-3">About Us</a>
      <a href="#" class="footer-link pt-3 ml-4">Help</a>
      <a href="#" class="footer-link pt-3 ml-4">Contact</a>
      <a href="#" class="footer-link pt-3 ml-4">Terms &amp; Conditions</a>
    </div>
    <div class="pt-3 ml-md-auto">
      <a class="text-twitter text-big" href="#">
        <i class="ion ion-logo-twitter"></i>
      </a>
      &nbsp; &nbsp;
      <a class="text-facebook text-big" href="#">
        <i class="ion ion-logo-facebook"></i>
      </a>
      &nbsp; &nbsp;
      <a class="text-instagram text-big" href="#">
        <i class="ion ion-logo-instagram"></i>
      </a>
    </div>
  </div>
</nav>