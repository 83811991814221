import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CourseCardComponent } from "./components/course-card/course-card.component";
import { UploadDialogComponent } from "./components/upload-dialog/upload-dialog.component";

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [CourseCardComponent, UploadDialogComponent],
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule

  ],
  exports: [CourseCardComponent, UploadDialogComponent]
})
export class SharedModule {}
